import { inject, observer } from "mobx-react";
import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ButtonKind, FauxButton, Grid, Heading } from "../../ui";
import { HeadingProps } from "../../ui/Heading.component";
import { MagicLink } from "@inferno/renderer-shared-ui";
import { DatasourceFeedItem, DatasourceProps } from "./Datasource.component";

export interface DatasourceTileProps extends DatasourceProps {
  url?: string;
}

export const MicrositeDatasourceTile = inject("store")(
  observer((props: DatasourceTileProps) => {
    const { store, title, content, feedId, displayHints, block, url = "#" } = props;
    const { t } = useTranslation();

    if (!store || !content?.length) {
      return null;
    }

    const heading: HeadingProps = useMemo(() => props.heading ?? { level: 2 }, [props.heading]);
    const { showEyebrow } = displayHints;
    const header = useMemo(() => {
      const headingTitle = title ? title : "Latest Posts";
      const link = !displayHints.isHorizontalSeeAll
        ? {
            text: t("see_all"),
            label: `${t("see_all")}${headingTitle}`,
            url,
          }
        : undefined;
      return (
        <Heading {...heading} link={link}>
          <MagicLink to={url} sectionName="articles" context="view_more">
            {headingTitle}
          </MagicLink>
        </Heading>
      );
    }, [title, heading, url, t, displayHints]);

    return (
      <Fragment key={block.id}>
        {header}
        <div className="feed-cards microsite" data-block-id={block.id} suppressHydrationWarning={true}>
          <Grid columns={4}>
            {content.map((item, i) => {
              return (
                <DatasourceFeedItem
                  key={`feed-item-${feedId}-${(item as any).ref_id}-${i}`}
                  item={item}
                  displayHints={displayHints}
                  index={i}
                  showEyebrow={showEyebrow}
                  microsite={store.microsite}
                />
              );
            })}
            {displayHints.isHorizontalSeeAll ? (
              <FauxButton className="see-all-horizontal" to={url} kind={ButtonKind.SECONDARY} size="small">
                {t("see_all")}
              </FauxButton>
            ) : null}
          </Grid>
        </div>
      </Fragment>
    );
  }),
);

export default MicrositeDatasourceTile;
